<template>
  <div class="common_sub_container">
    <h2>PASSWORD MANAGEMENT</h2>
    <section>
      <div class="two_tabs">
        <a
          @click="showTab1=true,showTab2=false"
          v-bind:class="{ active: showTab1 }"
          class="tab_buttons"
        >LOCKED USERS</a>
        <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >MANAGE PASSWORDS</a>
      </div>
      <LockedUsers v-show="showTab1" />

      <ManagePasswords v-show="showTab2" />
    </section>
  </div>
</template>
<script>
import LockedUsers from "./LockedUsers.vue";
import ManagePasswords from "./ManagePasswords.vue";
export default {
  name: "PasswordManagement",
  components: {
    LockedUsers,
    ManagePasswords
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  },
  created(){
    this.$emit("breadCrumbData",["Password Management"]);
  }
};
</script>
<style  scoped >
</style>