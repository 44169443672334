<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Name</th>
          <th>Unit</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
        <tr v-for="user in lockedUsers">
          <td>{{user.name}}</td>
          <td>{{user.branch.branch_id}}</td>
          <td>{{user.email}}</td>
          <td>
            <a class="table_action_btn" title="Unlock User" @click="unlockUser(user.id)">UNLOCK</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "x",
  components: {},
  data() {
    return {
      makeEditable: false,
      cancelEditable: true,
      lockedUsers: []
    };
  },
  created(){
    this.fetchLockedUsers();
  },
  methods: {
    fetchLockedUsers(){
      this.axios.get('/api/user/lockedusers')
      .then(response => {
        console.log(response);
          this.lockedUsers = response.data.locked_users;
      })
      .catch(error => console.log(error));
    },
    unlockUser(id) {
      let confirmed = confirm('Are you sure to unlock this user ?');
      if(confirmed) {
          this.axios.get('/api/user/unlock/'+id)
          .then(response => {
              this.removeUser(id);
              this.showSuccessToast();
          })
      }
    },
    removeUser(id) {
        let index = this.lockedUsers.findIndex(user => user.id === id);
        this.lockedUsers.splice(index, 1);
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 10px;
  vertical-align: middle;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 33.33%;
}

.table_action_btn {
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #063657;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>