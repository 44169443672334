<template>
  <div class="pop_container">
    <div class="title_area">CHANGE PASSWORD</div>
    <div class="body_area">
      <div class="form_section_generate_slip">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table class="change_pass_table">
          <tr>
            <td>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Name
                  <span></span>
                </label>
                <input type="text" v-model="user.name" disabled :class="errors[0] ? 'warning-border':''" />
              </div>
          </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
          <ValidationProvider name="new pwd" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Enter New Password
                  <span></span>
                </label>
                <input type="password" v-model="password.password" :class="errors[0] ? 'warning-border':''" />
              </div>
          </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
          <ValidationProvider name="confirm pwd" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Confirm New Password
                  <span></span>
                </label>
                <input type="password" v-model="password.confirm_password" :class="errors[0] ? 'warning-border':''" />
              </div>
          </ValidationProvider>
            </td>
          </tr>
        </table>
      </ValidationObserver>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="updatePwd">Save</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "popChangePassword",
  data(){
    return {
      password:{
        password:"",
        confirm_password:""
      }
    };
  },
  methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    updatePwd() {
      this.$refs.observer.validate();
      this.axios.put("/api/user/updatepassword/"+this.user.id,this.password)
      .then(response => {
        this.toast.success(response.data.msg);
        this.$router.go(this.$router.currentRoute);
      })
      .catch(error => {
        // this.toast.error(error.response.data.msg);
      })
    }
  },
  props: ["user"]
};
</script>
<style lang="scss">
.form_section_generate_slip table.change_pass_table {
  width: 400px;
  margin: auto;
  td {
    text-align: left;
    padding: 0;
    label {
      padding: 0;
      height: auto;
    }
  }
}
</style>