var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pop_container"},[_c('div',{staticClass:"title_area"},[_vm._v("CHANGE PASSWORD")]),_c('div',{staticClass:"body_area"},[_c('div',{staticClass:"form_section_generate_slip"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',{staticClass:"change_pass_table"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                Name\n                "),_c('span')]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"new pwd","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                Enter New Password\n                "),_c('span')]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.password),expression:"password.password"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"password"},domProps:{"value":(_vm.password.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "password", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"confirm pwd","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                Confirm New Password\n                "),_c('span')]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.confirm_password),expression:"password.confirm_password"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"password"},domProps:{"value":(_vm.password.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.password, "confirm_password", $event.target.value)}}})])]}}],null,true)})],1)])])]}}])}),_c('div',{staticClass:"pop_buttons_container"},[_c('a',{staticClass:"tr_cancel",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('a',{staticClass:"tr_save",on:{"click":_vm.updatePwd}},[_vm._v("Save")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }